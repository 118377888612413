@use "../../config/styleVariable.scss" as global;
.LoginScreen-txt1 {
  font-family: global.$Bold;
  font-size: 24px;
  text-align: center;
}
.LoginScreen-txt2 {
  font-family: global.$Medium;
  font-size: 15px;
  text-align: center;
  line-height: 30px;
  color: rgb(77, 77, 77);
}
.LoginScreen-txt3 {
  font-family: global.$SemiBold;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    justify-content: flex-end;
    padding-bottom: 30px;
  }
}
.LoginScreen-txt4 {
  font-family: global.$SemiBold;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  color: grey;
}
.LoginScreen-txt5 {
  font-family: global.$Bold;
  color: global.$primary;
}
.LoginScreen-errortxt {
  font-family: global.$Medium;
  color: red;
  font-size: 16px;
  margin-bottom: 10px;
  margin-bottom: 10px;
}

.LoginScreen-txt6 {
  text-align: center;
  font-family: global.$Bold;
  font-size: 17px;
  color: rgb(3, 76, 194);
  cursor: pointer;
}
.GmailLogin {
  border: 1px solid rgb(194, 194, 194);
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 4px;
}
.GmailLogin-row {
  font-family: global.$SemiBold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}
.GmailLogin-txt1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -10px;
  font-family: global.$SemiBold;
  font-size: 17px;
}
