@use "../../../config/styleVariable.scss" as global;
.Breadcrumb-container {
  font-size: 16px;
  font-weight: 600;
  margin-left: 2px;
  margin-bottom: 5px;
}

.ant-breadcrumb-separator {
  color: #3b3b3b !important;
}

.ant-breadcrumb a {
  color: #292929;
}
* {
  text-decoration: none !important;
}
.BreadCrump-txt1 {
  font-family: global.$SemiBold;
  color: grey;
  padding-right: 5px;
  font-size: 13px;
}
