@use "../../../config/styleVariable.scss" as global;
.PageHeader-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid #dbdbdb;
}
.PageHeader-IconBox {
  padding-right: 20px;
  border-right: 2px solid #dbdbdb;
  margin-right: 20px;
  padding-left: 20px;
}
.PageHeader-txt1 {
  font-family: global.$Bold;
  font-size: 20px;
  margin-bottom: -3px;
}
