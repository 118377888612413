.deactivateAccount-card {
    width: 600px; 
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .deactivateAccount-text1 {
    font-size: 24px;
    font-weight: bold;
    color: black;
    text-align: center;
  }
  
  .deactivateAccount-text2 {
    font-size: 16px;
    color: gray;
    text-align: center;
  }
  