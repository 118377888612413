@use "../../config/styleVariable.scss" as global;

.homeScreen-loaderBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-left: -10px;
}

.HomeScreen-BannerBox {
  width: 100%;
  overflow: hidden;
  overflow: hidden;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-top: 0px;
  }
}

.HomeScreen-Banners {
  width: 100%;
  height: 350px;
  background-color: rgb(235, 235, 235);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    z-index: 1;
  }

  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: 250px;
    background-size: cover;
    border-radius: 0px;
    &:before {
      width: 40%;
    }
  }
}

.HomeScreen-BannersBox {
  padding: 20px 30px;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  z-index: -1;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.Offers-box {
  border: 1px solid grey;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.Offers-img {
  width: 100%;
  height: 200px;

  img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
}

.HomeScreen-Bannertxt1 {
  font-family: global.$Medium;
  font-size: 14px;
}

.HomeScreen-Bannertxt2,
.HomeScreen-Bannertxt3 {
  position: relative;
  font-family: global.$SemiBold;
  color: white;
  z-index: 2;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 25px;
  }
}

.HomeScreen-Bannertxt3 {
  font-family: global.$Medium;
  font-size: 20px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    /* Adjust as needed */
  }
}

.homescreen-category-item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  text-align: center;
  font-family: global.$SemiBold;
  font-size: 15px;
  text-transform: capitalize;
  width: 120px;
  overflow: hidden;

  img {
    height: 100px;
    width: 100px;
    overflow: hidden;
    border-radius: 100px;
    border: 1px solid global.$grey6;
    padding: 3px;
    margin-bottom: 5px;
    background-color: global.$grey3;
  }

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 80px;
    font-size: 14px;

    img {
      height: 80px;
      width: 80px;
    }
  }
}

.homescreen-category-arrows {
  margin-bottom: 10px;
  font-size: 35px;
  color: global.$grey7;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}

.HomeSCreen-space {
  padding-top: 20px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-top: 10px;
  }
}

.Homescreen-TopSellingStore {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin: 15px;
  }
}

.homescreen-txt1 {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 0px;
    display: none;
  }
}

.Homescreen-txt2 {
  padding-top: 30px;
  margin-bottom: 20px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-top: 0px;
    margin-left: 0px;
    font-size: 20px;
    border-bottom: 1px solid rgb(244, 244, 244);
  }
}

.Homescreen-txt3 {
  color: global.$primary;
}

.flex-parent {
  ::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 992px) {
  .col-md-3.banner {
      flex: 0 0 auto;
      width: 20%;
  }
}