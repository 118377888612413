@use "../../config/styleVariable.scss" as global;

.MultiSearchProductList {
}

.MultiSearchProductList-Header {
  border-bottom: 1px solid rgb(231, 231, 231);
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-top: 20px;
    margin-bottom: 10px;
  }
}

.MultiSearchProductList-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: auto;
  }
}

.MultiSearchProductList-box2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: -20px;
  }
}

.MultiSearchProductList-box3 {
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-left: 0px;
    margin-left: -10px;
  }
}

.MultiSearchProductList-txt1 {
  font-family: global.$SemiBold;
  font-size: 20px;
  margin-block: 0px;
  cursor: pointer;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 18px;
  }
}

.MultiSearchProductList-txt2 {
  text-align: right;
  font-family: global.$Bold;
  font-size: 14px;
  color: global.$primary;
  cursor: pointer;
  margin-bottom: 10px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 0px;
  }
}

.MultiSearchProductList-txt3 {
  font-family: global.$Regular;
  font-size: 14px;
  color: gray;
  cursor: pointer;
}

.MultiSearchProductList-txt4{
  color: rgb(0, 151, 0);
  font-size: 12px;
}

.MultiSearchProductList-img {
  width: 65px;
  height: 65px;
  margin-right: 15px;
  object-fit: cover;
  border-radius: 50%;
  outline: 1px solid rgb(211, 211, 211);
  background-color: rgb(242, 242, 242);
}
