.card {
  width: 100%;
  height: 350px;
  // height: 23vw;
  // @media screen and (max-width: 768px) {
  //   height: 350px;
  // }
  cursor: pointer;
  & .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 768px) {
      object-fit: fill;
    }
  }

  & .text {
    top: 10px;
    left: 10px;
  }
}
