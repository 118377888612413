@use "../../config/styleVariable.scss" as global;
.product-card-pos {
  & .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & .img-container {
    height: 150px;
  }
  & p {
    margin: 0;
  }
}

.pos-subcategories {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.pos-tags {
  background-color: #e6e1e2;
  border-radius: 16px;
  padding-block: 4px;
  &:hover {
    // background-color: rgb(223, 223, 223);
    color: global.$primary;
  }
  &.active {
    background-color: global.$primary;
    color: white;
  }
}
.searchstore-website-link {
  color: global.$primary;
  &:hover {
    color: black;
  }
}
.pos-screen-pos-list-items {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  height: 100% !important;
}
.product-card-pos-title-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 4.7rem;
  @media (max-width: 767px) {
    height: auto;
  }
}
.product-card-pos-title {
  font-size: 16px;
  font-family: global.$Medium;
  @media (min-width: 767px) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* Limit to two lines */
    max-width: 123px;
  }
  @media (max-width: 767px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.pos-selected-item-list-discription{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}