@use "../../config/styleVariable.scss" as global;
$sidebar_menu_color: #f0e9ed;
$sidebar_menu_hover: #f0efef;

.ProfileScreen-box {
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 15px;
  min-height: 80vh;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.profile-sideBar {
  padding-top: 5px;
  width: 100%;
  height: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-right: 10px;
  border-right: 1px solid rgb(240, 240, 240);
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}

.profile-menu {
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 2px;
  color: rgb(76, 76, 76);
  border-radius: 6px;
  &:hover {
    background-color: $sidebar_menu_hover;
    color: global.$primary;
    cursor: pointer;
    padding: 10px 0;
    width: 100%;
    padding-left: 5px;
    margin-bottom: 2px;
  }
}
.profile-menu-selected {
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding-left: 5px;
  color: rgb(109, 109, 109);
  background-color: $sidebar_menu_color;
  color: global.$primary;
  margin-bottom: 2px;
  cursor: pointer;
  padding: 10px 0;
  width: 100%;
  padding-left: 5px;
}
.profile-sidebar-txt1 {
  font-family: global.$SemiBold;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
  margin-left: 10px;
}

.profile-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-txt1 {
  font-family: global.$SemiBold;
  font-size: 30px;
  padding-bottom: 20px;
  padding-top: 10px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 25px;
    padding-bottom: 10px;
    padding-top: 5px;
  }
}

.profile-dashboard-txt1 {
  font-size: 20px;
  color: grey;
  font-family: global.$Regular;
}
.profile-dashboard-txt2 {
  font-size: 25px;
  color: rgb(70, 70, 70);
  font-family: global.$SemiBold;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.profile-dashboard-txt5 {
  font-size: 16px;
  color: rgb(70, 70, 70);
  font-family: global.$SemiBold;
  margin-right: 20px;
}
.Modify_btn {
  height: 40px;
  width: 170px;
  border: none;
  border-radius: 5px;
  background-color: global.$primary;
  color: #fff;
}
.input_otp {
  display: flex;
  justify-content: start;
}
.profile-edit-btn {
  font-size: 16px;
  color: global.$primary;
  font-family: global.$SemiBold;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: global.$grey2;
  }
}
.profile-dashboard-Box2 {
  margin-right: 15px;
}
.profile-dashboard-Box3 {
  padding: 15px;
  font-family: global.$SemiBold;
  font-size: 20px;
}
.profile-dashboard-Box4 {
  text-align: center;
  color: global.$primary;
  font-size: 18px;
  font-family: global.$SemiBold;
}
.profile-dashboard-Box5 {
  color: global.$grey1;
  font-size: 20px;
  font-family: global.$SemiBold;
  // display: flex;
}
.profile-dashboard-input1 {
  padding-top: 10px;
}
.profile-dashboard-txt3 {
  font-size: 20px;
  color: rgb(62, 62, 62);
  font-family: global.$Regular;
}
.profile-dashboard-txt4 {
  font-size: 13px;
  color: rgb(111, 111, 111);
  font-family: global.$SemiBold;
}
.profile-dashboard-txt6 {
  font-size: 24px;
  color: rgb(111, 111, 111);
  font-family: global.$SemiBold;
}
.profile-dashboard-txt7 {
  font-size: 17px;
  font-family: global.$SemiBold;
}
.profile-dashboard-txt8 {
  font-size: 15px;
  font-family: global.$Regular;
}
.profile-dashboard-txt9 {
  font-size: 17px;
  font-family: global.$SemiBold;
  color: global.$primary;
}
.profile-dashboard-txt10 {
  line-height: 20px;
  font-size: 15px;
  font-family: global.$SemiBold;
}
.profile-dashboard-txt11 {
  list-style-type: none;
  line-height: 20px;
  font-size: 16px;
  font-family: global.$SemiBold;
}
.profile-dashboard-txt12 {
  line-height: 25px;
  list-style-type: none;
  font-size: 16px;
  font-family: global.$SemiBold;
}
.profile-dashboard-Box1 {
  display: flex;
  align-items: center;
  // flex-direction: row;
}
.profile-dashboard-Btn2 {
  background-color: global.$primary;
  font-size: 17px;
  font-family: global.$Bold;
  color: #fff;
  border: none;
  &:hover {
    border: none;
    color: global.$primary;
    background-color: #fff;
    border: 2px solid global.$primary;
  }
}

.emptyOrders {
  width: "100%";
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.emptyOrders-txt1 {
  color: global.$primary;
  font-family: global.$SemiBold;
  font-size: 25px;
  margin-top: 15px;
}

.emptyOrders-txt2 {
  color: global.$grey2;
  font-family: global.$SemiBold;
  font-size: 15px;
  margin-top: 15px;
  text-align: center;
}

.emptyButton {
  height: 45px;
  width: 200px;
  font-family: global.$SemiBold;
  font-size: 15px;
}
// PhoneVerifyOtp-------------->
.PhoneVerifyOtp-txt2 {
  font-size: 20px;
  font-family: global.$Bold;
  color: rgb(70, 70, 70);
}
.PhoneLogin {
  padding: 6px;
}
// editName------------------ ->
.editName-Text1 {
  padding: 6px;
}
.editName-Text2 {
  text-align: center;
  font-family: global.$Bold;
  font-size: 20px;
  color: rgb(70, 70, 70);
}
.NameChange_1 {
  display: flex;
  align-items: center;
}
.Enter_ChangeName_2 {
  font-size: 16px;
  color: rgb(70, 70, 70);
  font-family: global.$SemiBold;
  // margin-right: 20px;
}
.profile-dashboard-Box-1 {
  background-color: yellow;
  display: flex;
  align-items: center;
}
.profile-dashboard-txt-5 {
  font-size: 16px;
  color: rgb(70, 70, 70);
  font-family: global.$SemiBold;
  margin-right: 20px;
}

// editEmail------------------->
.editEmail-Text2 {
  text-align: center;
  font-family: global.$Bold;
  font-size: 20px;
  text-align: center;
  color: rgb(70, 70, 70);
}
.profileDashboard-avatar {
  border: 2px solid global.$primary;
}
.profileDashboard-hrStyle {
  width: 50%;
}

.profilescreen-address-add-btn {
  text-align: start;
  width: 100%;
  font-size: 18px;
  height: auto;
  margin: 0.5rem 0px;
}
hr.profile {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(224, 224, 224);
}
.ant-card-meta-title {
  margin-bottom: 0;
}
.order-list-item {
  &:hover {
    background-color: rgb(248, 248, 248);
  }
}
.ant-list-sm {
  padding: 0px;
}

.profile-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FailedOrder-CartItem {
  border-bottom: 1px solid rgb(222, 221, 221);
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    align-items: flex-start;
    padding: 10px 0px;
  }
}
.FailedOrder-CartItem-img {
  height: 75px;
  width: 75px;
  margin-right: 10px;
  border-radius: 10px;
  object-fit: contain;
  border: 1px solid rgb(186, 186, 186);
  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: 45px;
    width: 45px;
  }
}
.FailedOrder-CartItem-txt1 {
  font-family: global.$Bold;
  font-size: 17px;
  text-transform: capitalize;
}
.FailedOrder-CartItem-txt55 {
  font-family: global.$Medium;
  font-size: 14px;
  color: rgb(151, 151, 151);
}
.FailedOrder-CartItem-txt2 {
  font-family: global.$Medium;
  font-size: 14px;
  color: rgb(94, 94, 94);
}
.FailedOrder-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.FailedOrder-CartItem-txt3 {
  margin-right: 15px;
  font-family: global.$Bold;
  font-size: 20px;
}
.favorite_list_item {
  &:hover .favorite_product_name{
    color: global.$primary;
  }
}
