@use "../../config/styleVariable.scss" as global;
.productByCat-PageSider {
  border-right: 1px solid rgb(231, 231, 231);
  height: calc(100vh - 74px);
  overflow-y: auto;
  position: sticky;
  ::-webkit-scrollbar {
    display: none;
    width: 5x;
  }
  scrollbar-width: none;
  position: -webkit-sticky;
  top: 0;
  @media (max-width: 992px) {
    display: none;
  }
}

.productByCat-PageHeader {
  padding-top: 10px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-top: 10px;
  }
}

.productByCat-PageHeadertxt1 {
  font-family: global.$Bold;
  font-size: 27px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    font-size: 18px;
  }
}

.productByCat-PageHeadertxt2 {
  font-family: global.$SemiBold;
  color: gray;
  font-size: 14px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-bottom: 5px;
  }
}

.productByCat-PageHeaderBox {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    justify-content: flex-start;
  }
}

.productByCat-PageSiderItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 10px;
  font-family: global.$Medium;
  font-size: 14px;
  padding-left: 2px;
  cursor: pointer;
  &:hover {
    color: #cc0055;
    background-color: rgb(241, 241, 241);
    border-radius: 6px;
  }
  &.active {
    background-color: rgb(241, 241, 241);
    border-radius: 6px;
  }
}

.productByCat-img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 100px;
  background-color: rgb(231, 231, 231);
}
