@use "../../config/styleVariable.scss" as global;

.footer {
  background: rgba(217, 217, 217, 0.4);
}
.footer-container {
  padding: 30px 0px 25px;
  @media screen and (max-width: 576px) {
    text-align: center;
  }
}
.Footer_text1 {
  font-family: global.$SemiBold;
  font-size: 18px;
  margin-bottom: 7px;
}
.Footer_text2 {
  padding-bottom: 15px;
  a {
    font-family: global.$Medium;
    text-decoration: none;
    color: black;
    font-size: 14px;
  }
}
.Footer_text3 {
  color: black;
  font-family: global.$Medium;
  font-size: 15px;
}
.list-container {
  @media screen and (max-width: 576px) {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
  }
}
.list-heading {
  font-size: 14px;
  font-family: global.$SemiBold;
  font-weight: 700;
  padding-bottom: 12px;
}
.listFooter-logo {
  height: 50px;
  margin-bottom: 16px;
  cursor: pointer;
}
.Payment-cards {
  width: 65%;
  display: flex;
  justify-content: space-between;
}
.payment_icons {
  width: 40px;
}
.social_icons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.Footer_download_Box {
  display: flex;
  gap: 5px;
}
.Footer_download_Icons {
  width: 130px;
  height: 40px;
}
.Footer_Box2 {
  border: 1px solid #e0e0e0;
  padding: 10px 0px 10px;
}
.copyright-txt {
  font-family: global.$SemiBold;
  font-size: 10px;
  text-align: center;
}
