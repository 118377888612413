@use "../../../config/styleVariable.scss" as global;

.current-location-box {
  display: flex;
  border: 1px solid rgb(193, 193, 193);
  padding: 10px;
  border-radius: 6px;
}
.current-location-box2 {
  margin-left: 20px;
  cursor: pointer;
}
.current-location-txt1 {
  font-family: global.$Medium;
  font-size: 14px;
  color: gray;
}
.current-location-txt3 {
  font-size: 18px;
  font-family: global.$SemiBold;
}
.current-location-txt4 {
  text-align: center;
  font-family: global.$Bold;
  font-size: 20px;
}
.current-location-txt5 {
  text-align: center;
  color: gray;
}
.profileMenu-Icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(244, 244, 244);
}
