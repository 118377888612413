.expolore-all-image {
  height: 60px;
  object-fit: cover;
  width: 60px;
  align-self: center;
}
.explore-all-card-title{
    font-size: 17px;
    color: rgb(65, 65, 65);
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}
.explore-all-card-container{
    cursor: pointer;
}