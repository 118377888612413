@use "../../config/styleVariable.scss" as global;

.productsOverView-Boxsettlement {
    width: 100%;
    background: rgb(239, 239, 239);
    border-radius: 10px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    min-height: 150px;
  }

  .productsOverView-Txt1settlement {
    font-family: global.$Bold;
    font-size: 18px;
    @media screen and (min-width: 768px) and (max-width: 968px) {
      font-size: 15px;
    }
  }
  .productsOverView-Txt2settlement {
    font-family: global.$Bold;
    font-size: 30px;
    @media screen and (min-width: 768px) and (max-width: 968px) {
      font-size: 19px;
    }
  }